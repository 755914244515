import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import algoliasearch from "algoliasearch/lite"

import SearchContext from "../context/SearchContext"
import Work from "./work"
import Loader from "./loader"

const client = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
)

export default function Results({ id }) {
  const [isLoading, setIsLoading] = useState(true)
  const [hits, setHits] = useState([])
  const { refinementList, isDefault } = useContext(SearchContext)
  const index = client.initIndex(process.env.GATSBY_ALGOLIA_INDEX)

  useEffect(() => {
    (async function() {
      const result = await index.search("", {
        "getRankingInfo": true,
        "analytics": false,
        "enableABTest": false,
        "hitsPerPage": 50,
        "attributesToRetrieve": "*",
        "attributesToSnippet": "*:20",
        "snippetEllipsisText": "…",
        "responseFields": "*",
        "page": 0,
        "maxValuesPerFacet": 100,
        "sumOrFiltersScores": true,
        "facets": [
          "*",
          "category",
          "format",
          "genre",
          "original",
          "target"
        ],
        "facetFilters": [
          // "original:Original Ip"
          ...refinementList.original.map(value => `original:${value}`),
          ...refinementList.format.map(value => `format:${value}`),
          ...refinementList.genre.map(value => `genre:${value}`),
          ...refinementList.target.map(value => `target:${value}`)
        ],
        "filters": id ? `NOT id:${id}` : ""
      })
      setIsLoading(false)
      if (isDefault) {
        function shuffle(arr) {
          const newArr = arr.slice()
          for (let i = newArr.length - 1; i > 0; i--) {
            const rand = Math.floor(Math.random() * (i + 1));
            [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]]
          }
          return newArr
        }

        return setHits(shuffle(result.hits))
      }
      setHits(result.hits)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refinementList, setHits, isDefault, id, setIsLoading])
  if (isLoading) {
    return <Loader />
  }
  if (id && (isDefault || hits.length === 0)) {
    return <span/>
  }
  if (id) {
    return (
      <div className="container is-widescreen">
        <h2 className="title is-2 has-text-centered has-text-primary">
          You may also like
        </h2>
        <div className="columns is-multiline">
          {hits.map((work, index) => (
            <Work
              key={work.id}
              index={index}
              work={work}
            />
          ))}
        </div>
      </div>
    )
  }
  return (
    <div className="columns is-multiline">
      {hits.map((work, index) => (
        <Work
          key={work.id}
          index={index}
          work={work}
        />
      ))}
    </div>
  )
}
Results.propTypes = {
  id: PropTypes.string
}
