import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledWrapper = styled.figure`
  position: relative;
  opacity: 0;
  overflow: hidden;
  figure, img {
    border-radius: 10px;
  }
  img {
    object-fit: cover;
  }
  @media (hover: hover) {
    animation-delay: ${({ index }) => `${index * 100}ms`};
    transition: transform 200ms ease;
    &:hover {
      transition: transform 500ms ease;
      transform: scale(1.05);
      p {
        transition: opacity 500ms ease;
        opacity: 1;
      }
    }
  }
  p {
    position: absolute;
    border-bottom-left-radius: 10px;
    z-index: 100;
    background-color: rgba(0,0,0,.6);
    padding: 10px;
    left: 0;
    bottom: 0;
    opacity: 0;
    
    @media (hover: none) {
      opacity: 1;
    }
    @media screen and (max-width: 769px) {
      font-size: 14px;
    }
  }
  
    &:hover {
    transition: transform 500ms ease;
      transform: scale(1.05);
    }
`
export default function Work({ index, work }) {
  return (
    <StyledWrapper className="column is-4 animate__animated animate__fadeIn" index={index}>
      <Link to={`/works/${work.slug}`}>
        <figure className="image">
          <img
            src={work.featured_media.localFile.childImageSharp.resize.src}
            alt={work.title}
          />
          {work.preview && (
            <p className="has-text-primary has-text-weight-semibold animate__animated animate__slideInUp has-text-left">
              {work.preview}
            </p>
          )}
        </figure>
      </Link>
    </StyledWrapper>
  )
}
Work.propTypes = {
  index: PropTypes.number.isRequired,
  work: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    preview: PropTypes.string,
    slug: PropTypes.string.isRequired,
    featured_media: PropTypes.shape({
      localFile: PropTypes.shape({
        childImageSharp: PropTypes.shape({
          resize: PropTypes.shape({
            src: PropTypes.string
          })
        })
      })
    }).isRequired
  })
}
